/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue';
window.jQuery = require('jquery');

window.axios = require('axios');
// Vue.use(ToggleButton);
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
const activeNote = {}
var bus = new Vue();
global.bus = bus;

// const Admin = require('./components/Admin.vue').default;
const Admin = () => import( './components/Admin.vue');
const Episodes = () => import('./components/Episodes.vue');
const Episodesearch = () => import('./components/EpisodeSearch.vue');
const Filmography = () =>import('./components/Filmography.vue');
const Crew = () => import('./components/Crew.vue');
const Notes = () => import('./components/Notes.vue') ;
const Roles = () => import('./components/Roles.vue');
const Links = () => import('./components/Links.vue') ;
const Orgs = () => import('./components/Orgs.vue') ;
const Search = () => import('./components/Search.vue') ;
const Csrow = () => import('./components/Crowdsource.vue') ;
const Metaupdate = () => import('./components/Meta.vue') ;
const Quickpager = () => import('./components/QuickPager.vue') ;
const Selbox = () => import('./components/Selbox.vue') ;
import Mapb from './components/Map.vue';

var app = new Vue({
    el: '#app',
    components: {Admin, Episodes, Episodesearch, Filmography, Crew, Notes, Roles, Links, Orgs, Search, Csrow, Metaupdate, Quickpager, Selbox, Mapb}
});
