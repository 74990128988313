<template>
<div>
    <i v-show="loading" class="fa fa-2x fa-compass fa-spin"></i>
    <div class="map-wrapper">
        <div id="mapContainer" class="basemap"></div>
        <div :class="{'popup-box': true, 'inactive': spotted == false}" @click="getPage(thisSpot)">
            <h2 @click="getPage(thisSpot)">{{ thisSpot.title }}</h2>
            <div class="content-wrapper">
                <img :src="thisSpot.thumbnail"  @click="getPage(thisSpot)" />
                <div class="description" v-html="thisSpot.description"></div>
            </div>
            <div class="bottom-tag">View Product page</div>
            <div class="close" @click="close()"><i class="fa fa-close"></i></div>
            <div class="notch"></div>
        </div>
        <div class="spot-list">
            <h3>Select a Piece<div class="badge">{{ totalSpots }}</div></h3>
            <div class="spot-wrap">
                <i v-show="loading" class="fa fa-compass fa-2x fa-spin"></i>
                <!-- <ul> -->
                    
                    <transition-group name="fadespot" v-on:after-leave="countSpots" v-on:after-enter="countSpots">
                        <div v-show="checkTags(spot.bettertags)" v-for="spot in spots" v-on:mouseover="findSpot(spot.longitude, spot.latitude)" :class="{'active':(thisSpot == spot), 'spot': true, 'shown': checkTags(spot.bettertags)}" @click="flyTo(spot)" :key="spot.id">{{ spot.title }}
                            <div class="tags"><span v-for="tag in spot.bettertags">{{ tag.name }}&nbsp;</span></div>
                        </div>
                    </transition-group>
                        <!-- spot.tags.indexOf(selectedCat) > -1 || selectedCat=='' -->
                <!-- </ul> -->
            </div>
        </div>
    </div>
</div> 
</template>
<!-- <link href='https://api.mapbox.com/mapbox-gl-js/v1.10.1/mapbox-gl.css' rel='stylesheet' /> -->
<script>
   import mapboxgl from 'mapbox-gl';

    export default {
        name: 'Map',
        data: function() {
            return {
                mbtoken: "pk.eyJ1IjoiYWMwMHBlcnciLCJhIjoiY2tvMzQyZnF4MDY2NjJ3czI2aTN4dHBzZiJ9.kSlootcpGRiN3LjT2qzHHg",
               loading: false,
               spots: [],
               locations: [],
               pieceTypes: [],
               map: {},
               spotted: false,
               thisSpot: [],
               selectedCat: '',
               totalSpots: 0
            }
        },

        mounted: function(){
            console.log('ff')
            mapboxgl.accessToken = this.mbtoken;
            const map = new mapboxgl.Map({
              container: "mapContainer",
              style: "mapbox://styles/mapbox/streets-v11",
              center: [43.872754, -72.772265],
              zoom: 14,
              // maxBounds: [
              //   [-73.43774,42.726853],
              //   [-71.464555,45.016659],
              // ],
            });

            
            //everything happens after the map is mounted;
            const geolocate = new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true
              },
              trackUserLocation: true
            });

            map.addControl(geolocate, "top-right")
            // map.addControl(new mapboxgl.ZoomControl(), "top-left")
            map.addControl(new mapboxgl.NavigationControl(), "top-left");
        },
        methods: {
            countSpots: function(){
                this.totalSpots = document.getElementsByClassName('shown').length;
                this.$forceUpdate();
            },
            checkTags: function(tags){
                var vm = this;

                //return for all
                if(vm.selectedCat==''){
                    return true;
                }
                for(var t in tags){
                    if(tags[t].name == vm.selectedCat){
                        return true;
                    }
                }
                // if(tags.indexOf(vm.selectedCat) > -1 || vm.selectedCat==''){
                //     return false;
                // }else{
                //     return true;
                // }

            },
            addMarkers: function(layerData) {
                var map = this.map;
                var vm = this;

                var maplayer= map.addLayer({
                        'id': 'points',
                        'type': 'symbol',
                        'source': {
                          'type': 'geojson',
                          'data': {
                            'type': 'FeatureCollection',
                            'features': layerData
                          }
                        },
                        'layout': {
                          'icon-image': '{icon}-15',
                          'text-field': '{title}',
                          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                          'text-offset': [0, 0.6],
                          'text-anchor': 'top'
                        }
                      });
                 this.flyToOverview();
                 this.loading = false;
            },
            mapMouseMoved(map, e) {
              const features = map.queryRenderedFeatures(e.point, {
                layers: ['points']
              });
              map.getCanvas().style.cursor = (features.length) ? 'pointer' : '';
            },
            mapClicked: function(map, e){
                var vm = this;
                // console.log(e);
                // console.log(e.point.longitude);
                var features = map.queryRenderedFeatures(e.point, {layers: ['points']});
                if (!features.length) {
                    return;
                }
                var feature = features[0];

                for(var spot in vm.spots){
                    if(vm.spots[spot].id == feature.properties.id){
                        // vm.thisSpot = vm.spots[spot];
                        vm.flyTo(vm.spots[spot]);
                        break;
                    }
                }
            },
            getSpots: function() {
                var vm = this;
                axios.get('/?latlon=1')
                .then(function(c){
                    vm.spots = c.data;
                    var layerData = [];
                    for(var spot in vm.spots){
                        vm.spots[spot]['show']=true;
                        var s=vm.spots[spot];
                        vm.addCategories(s.bettertags);
                        // vm.spots[spot].taglist=vm.arrayToSentence(s.tags);
                        layerData.push({
                              'type': 'Feature',
                              'geometry': {
                                'type': 'Point',
                                'coordinates': [s.longitude, s.latitude]
                              },
                              'properties': {
                                'title': s.title,
                                'icon': 'marker',
                                'id': s.id
                              }
                            });
                    }
                    vm.categories.unshift('All Items');
                    vm.addMarkers(layerData);
                })
                .catch(function(error){
                    console.log(error);
                })
            },
            mapInitialized: function(map){
                // console.log('init');
                this.map = map;
            },
            mapLoaded: function(map){
                this.map = map;
                // this.getSpots();
            },
            getPage: function(s){
                // console.log(s.permalink);
                if(s.permalink !== undefined){
                    // console.log('load new href')
                    window.location.href=s.permalink;
                }
            },
            close: function(){
              this.thisSpot = [];
              this.spotted = false;
              // console.log('close');
            },
            arrayToSentence: function (arr) {
                var last = arr.pop();
                return arr.join(', ') + ' and ' + last;
            },
            flyToOverview(){
                this.map.flyTo({
                    bearing: 0,
                    curve: 1.2,
                    speed: 2.5,
                    center: [-72, 43],
                    zoom: 1.5,
                    pitch: 20
                });
            },
            flyTo: function(spot){
                var vm = this;
                if(spot == vm.thisSpot){
                    return;
                }
                vm.thisSpot = spot;
                vm.map.once('movestart', function() {
                    // Duration the slide is on screen after interaction
                    // console.log('moved');
                    vm.spotted = false;
                });
                vm.spotted = false;
                // console.log(spot);
                vm.map.flyTo({
                    bearing: 0,
                    curve: 1.2,
                    speed: 2.5,
                    center: [spot.longitude, spot.latitude],
                    zoom: 13,
                    pitch: 40
                });
                vm.map.once('moveend', function () {
                    // Duration the slide is on screen after interaction
                    // console.log('moved');
                    vm.spotted = true;
                });
            },
            addCategories: function (cats) {
                var vm = this;
                for(var cat in cats){
                    switch (cats[cat].parent){
                        case 'Locations':
                            if(vm.locations.indexOf(cats[cat].name) == -1){
                                vm.locations.push(cats[cat].name);
                            }
                            break;
                        case 'Jewelry':
                        case 'null':
                            if(vm.pieceTypes.indexOf(cats[cat].name) == -1){
                                vm.pieceTypes.push(cats[cat].name);
                            }
                            break;
                    }
                }
                vm.pieceTypes.sort();
                vm.locations.sort();
                vm.categories=vm.pieceTypes.concat(vm.locations);
                // vm.$forceUpdate();
            },
            selectCat: function (cat, index) {
                var vm = this;
                if(index == 0){
                    vm.selectedCat='';
                    return false;
                }
                vm.selectedCat = cat;
                // console.log(vm.$refs.spot.childElementCount);
                vm.$forceUpdate();

            },
            findSpot: function(lng, lat){
                // var vm = this;
                // vm.spotted = false;
                // // console.log(lat, lng);
                // // var point = new mapboxgl.Point(lng, lat);
                // // const features = vm.map.queryRenderedFeatures([lng, lat], {
                // // layers: ['points']});
                // vm.map.flyTo({zoom: 5, center: [lng, lat]});
                // vm.$forceUpdate();
            }
        }
  }
</script>

<style scoped lang="scss">
.basemap {
  width: 100%;
  height: 80vh;
}

ul li{
    line-height: 1.1em;
}
.active {
    // border-bottom: 1px solid rgba(0,0,0,.2);
    background-color: rgba(250, 200, 0, .5);
}
h1{
  margin: 0;
}
.categories{
    display: flex;
    width: 100%;
    min-height: 45px;
    align-self: flex-start;
    margin: 5px;
    .inline-list{
        list-style-type: none;
        padding-left: 5px;
        li{
            float: left;
            margin: 0 5px 0 5px;
            cursor: pointer;
            padding: 5px;
            transition: all .15s ease-in-out;
            font-size: .9em;
            &:hover, &.active{
                @extend .active
            }
        }
    }
}
.map-wrapper{
    position: relative;
    display: flex;
    width: 100%;
    overflow: hidden;
    padding-top: 10px;
    // align-items: center;
    .popup-box{
        // .notch {
        //     position: absolute;
        //     bottom: -10px;
        //     left: 49%;
        //     transform: translateX(-50%) rotate(45deg);
        //     margin: 0;
        //     padding: 0;
        //     width: 20px;
        //     height: 20px;
        //     background: rgb(250,250,250);
        //     box-shadow: 1px 1px 5px rgba(0,0,0,.45);
        // }
        h2{
            font-weight: bold;
            line-height: 1.1em;
            padding: 0;
            margin: 0 !important;
        }
        border-radius: 6px;
        position: absolute;
        top: 20px;
        left: 30%;
        transform: translateX(-30%);
        width: 50%;
        height: 250px;
        z-index: 9212;
        background-color: rgb(250, 250, 250);
        padding: 10px;
        box-shadow: 1px 1px 5px rgba(0,0,0,.45);
        transition: all .25s ease-in-out;
        overflow: hidden;
        @media screen and (max-width: 768px){
            width: 90%;
        }
        &.inactive{
            top: -300px;
        }
        .close{
          position: absolute;
          top: 0;
          right: 0;
          z-index: 9999;
          padding: 5px 10px;
              &:hover{
                color: red;
              }
        }
        & *{
          cursor: pointer;
        }
        .content-wrapper{
            clear: both;
            .description {
                float: right;
                width: 79%;
                height: 190px;
                overflow: hidden;
                @media screen and (max-width: 768px){
                    width: 59%;
                }
            }
            img{
                margin-top: 5px;
                float: left;
                width: 19%;
                @media screen and (max-width: 768px){
                    width: 39%;
                }
            }
        }
        .bottom-tag{
            z-index: 1200;
            // background-color: transparent;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 55px;
            text-align: center;
            font-weight: bold;
            line-height: 2.2em;
            padding-top: 20px;
            border-radius: 6px;
            background: linear-gradient(to bottom, rgba(250, 250, 250, 0) 0%, rgb(250,250,250) 40%);
        }
    }
    #map{
        width: 79%;
        height: 600px;
        @media screen and (max-width: 768px){
            width: 59%;
        }
        .marker {
              // background-image: url('../../images/loading.gif');
              background-size: cover;
              width: 50px;
              height: 50px;
              border-radius: 50%;
              cursor: pointer;
            }
    }
    .spot-list{
        align-self: flex-start;
        position: relative;
        width: 19%;
        cursor: pointer;
        margin-left: 0;
        height: 600px;
        @media screen and (max-width: 768px){
            width: 39%;
        }
        .fa{
            margin: 10px;
        }
        .spot-wrap{
            height: 567px;
            overflow-y: auto;
            display: block;
            position: relative;
        }
        h3{
            padding-left: 10px;
            font-weight: bold;
            font-size: 1.4em;
            margin: 0 10px 10px 10px;
            padding: 0;
            position: relative;
            width: 60%;
        }
        .badge{
            position: absolute;
            @extend .active;
            top:-5px;
            right:-10px;
            padding: 4px;
            font-size: 14px;
            color: #333333;
            width:22px;
            height:20px;
            text-align:center;
            line-height:15px;
            border-radius:40%;
            }
        .spot{
                padding: 5px 10px 5px 10px;
                margin-bottom: 5px;
                font-size: .9em;
                font-weight: 600;
                min-height: 60px;
                // transition: all .5s ease-in-out;
                &:hover, &.active{
                    
                    @extend .active;
                    .tags{
                        color: #666666;
                    }
                }
                .tags{
                    font-size: .8em;
                    font-weight: normal;
                    color: #dddddd;
                    transition: color .25s ease-in-out;
                }
            }

        
    }
}

.fadespot-enter-active {
    animation: bounce-in .3s;
}
.fadespot-leave-active {
    animation: bounce-in .3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
  
</style>
